import { Injectable } from '@angular/core';
import difference from 'lodash-es/difference';

import { InventoryDevice } from '@shure/cloud/shared/models/devices';
import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';

export interface HostDevice {
	id: string;
	model: DeviceModel;
}

@Injectable({ providedIn: 'root' })
export class DeviceRelationshipService {
	private hostToProxiesMap = new Map<string, string[]>();
	private proxiedToHostMap = new Map<string, HostDevice>();

	public registerProxiedDevices(hostDevice: InventoryDevice): void {
		const existingProxiedIdsForHost = this.hostToProxiesMap.get(hostDevice.id) ?? [];
		const proxiedDeviceIds = (hostDevice.features.proxiedDevices?.proxiedDevices ?? []).map((device) => device.id);

		// Remove devices that are no longer being proxied by the host
		for (const removedId of difference(existingProxiedIdsForHost, proxiedDeviceIds)) {
			this.proxiedToHostMap.delete(removedId);
		}

		// Add/update devices that are being proxied
		if (proxiedDeviceIds.length > 0) {
			this.hostToProxiesMap.set(hostDevice.id, proxiedDeviceIds);
		}

		for (const proxiedId of proxiedDeviceIds) {
			this.proxiedToHostMap.set(proxiedId, hostDevice);
		}
	}

	public removeDevice(deviceId: string): void {
		// If host device, remove host and all proxied mappings
		const proxiedIds = this.hostToProxiesMap.get(deviceId);
		if (proxiedIds) {
			this.hostToProxiesMap.delete(deviceId);
			for (const proxiedId of proxiedIds) {
				this.proxiedToHostMap.delete(proxiedId);
			}

			return;
		}

		// If proxied device, remove proxied and host mappings
		const hostDevice = this.proxiedToHostMap.get(deviceId);
		if (hostDevice) {
			this.proxiedToHostMap.delete(deviceId);
			this.hostToProxiesMap.delete(hostDevice.id);
		}
	}

	public getHostDevice(proxiedDeviceId: string): HostDevice | undefined {
		const hostDevice = this.proxiedToHostMap.get(proxiedDeviceId);
		return hostDevice
			? {
					id: hostDevice.id,
					model: hostDevice.model
			  }
			: undefined;
	}
}
