import { Observable } from 'rxjs';

export interface DeviceDiscoveryEvent {
	type: 'deviceAdded' | 'deviceRemoved';
	id: string;
}

export abstract class DeviceDiscoveryApiService {
	public abstract discoveryComplete$: Observable<boolean>;

	// For convenience, expose a count of the number of discovered devices that isn't emitted
	// until device discovery is completed.
	public abstract discoveredDevicesCountAfterDiscovery$(): Observable<number>;

	public abstract discoveredDevicesCount$(): Observable<number>;

	// An observable that emits the invdividual deviceAdded and deviceRemoved events
	// as they occur.
	public abstract discoveryEvents$(): Observable<DeviceDiscoveryEvent>;
}
