import { Inject, Injectable } from '@angular/core';
import {
	BehaviorSubject,
	combineLatest,
	filter,
	map,
	Observable,
	retry,
	Subject,
	take,
	takeUntil,
	timeout
} from 'rxjs';

import { OktaInterfaceService, monitorLoginState } from '@shure/cloud/shared/okta/data-access';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { DeviceDiscoveryApiService, DeviceDiscoveryEvent } from '../api/device-discovery-api.service';

import {
	DiscoveredDevicesQueryGQL,
	DiscoveredDevicesQueryOpResult,
	DiscoveredDevicesSubscriptionGQL,
	DiscoveryDeviceFragment
} from './graphql/generated/cloud-sys-api';

@Injectable({ providedIn: 'root' })
export class SysApiDeviceDiscoveryApiService implements DeviceDiscoveryApiService {
	public readonly discoveryComplete$ = new BehaviorSubject(false);
	private discoveredDevicesMap = new Map<string, DiscoveryDeviceFragment>();
	private discoveredDevices = new BehaviorSubject<DiscoveryDeviceFragment[]>([]);
	private discoveryEvents = new Subject<DeviceDiscoveryEvent>();
	private destroy$ = new Subject<void>();
	private readonly logger: ILogger;
	private serviceInitialized = false;

	constructor(
		logger: ILogger,
		private readonly discoveredDevicesQueryGQL: DiscoveredDevicesQueryGQL,
		private readonly discoveredDevicesSubscriptionGQL: DiscoveredDevicesSubscriptionGQL,
		private readonly oktaService: OktaInterfaceService,
		@Inject(APP_ENVIRONMENT) private readonly appEnv: AppEnvironment
	) {
		this.logger = logger.createScopedLogger('DeviceDiscoveryService');

		monitorLoginState(this.oktaService, {
			onLogIn: this.initService.bind(this),
			onLogOut: this.suspendService.bind(this)
		});
	}

	public discoveredDevicesCountAfterDiscovery$(): Observable<number> {
		return combineLatest([this.discoveryComplete$, this.discoveredDevicesCount$()]).pipe(
			filter(([discoveryComplete, _deviceCount]) => discoveryComplete === true),
			map(([_loadingStateInfo, deviceCount]) => deviceCount)
		);
	}
	public discoveredDevicesCount$(): Observable<number> {
		return this.discoveredDevices.asObservable().pipe(map((deviceFragments) => deviceFragments.length));
	}

	public discoveryEvents$(): Observable<DeviceDiscoveryEvent> {
		return this.discoveryEvents.asObservable();
	}

	private initService(): void {
		// this check is a preventative meausre to ensure that if we received two "true" transitions
		// in a row, this service won't re-discover devices again.
		if (this.serviceInitialized === true) {
			this.logger.error('initService', 'initService called when already initialized');
			return;
		}
		this.serviceInitialized = true;
		this.logger.information('initService', 'user logged in, initializing service');

		// we need a new destroy$ subject since it is "completed" when the user logs out.
		this.destroy$ = new Subject();

		this.discoveryComplete$.next(false);
		this.subscribeDiscoveredDevices();
		this.queryDiscoveredDevicesNetwork(null);
	}

	private suspendService(): void {
		this.serviceInitialized = false;
		this.discoveryComplete$.next(true);
		this.logger.information('suspendService', 'user logged out, suspending service');
		this.destroy$.next();
		this.destroy$.complete();
	}

	private handleDevicesAdded(devices: DiscoveryDeviceFragment[]): void {
		devices.forEach((device) => {
			this.discoveredDevicesMap.set(device.id, device);
			this.discoveryEvents.next({ type: 'deviceAdded', id: device.id });
		});
		this.discoveredDevices.next([...this.discoveredDevicesMap.values()]);
	}

	private handleDeviceRemoved(id: string): void {
		this.discoveryEvents.next({ type: 'deviceRemoved', id: id });
		this.discoveredDevicesMap.delete(id);
		this.discoveredDevices.next([...this.discoveredDevicesMap.values()]);
	}

	private subscribeDiscoveredDevices(): void {
		this.discoveredDevicesSubscriptionGQL
			.subscribe(
				{},
				{
					errorPolicy: 'ignore',
					fetchPolicy: 'no-cache' //  always fetch from network, no need to store in cache
				}
			)
			.pipe(
				retry({
					count: 2,
					delay: 10_000
				}),
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: (change) => {
					if (change.data) {
						if ('added' in change.data.discoveredDevices) {
							this.handleDevicesAdded([change.data.discoveredDevices.added]);
						} else if ('removed' in change.data.discoveredDevices) {
							this.handleDeviceRemoved(change.data.discoveredDevices.removed);
						}
					}
				}
			});
	}

	private queryDiscoveredDevicesNetwork(afterCursor: string | null): void {
		const pageSize = this.appEnv.cdmPerformance?.connectionsQueryPageSize ?? 3000;
		this.discoveredDevicesQueryGQL
			.fetch(
				{
					first: pageSize,
					after: afterCursor,
					deviceModels: []
				},
				{ fetchPolicy: 'no-cache' }
			)
			.pipe(
				retry({
					count: 2,
					delay: 5_000
				}),
				take(1),
				timeout(30_000)
			)
			.subscribe({
				next: (result) => {
					this.processDiscoveredDevicesQueryResult(result.data);
				},
				error: () => {
					this.handleDevicesAdded([]);
					this.discoveryComplete$.next(true);
				}
			});
	}

	private processDiscoveredDevicesQueryResult(devices: DiscoveredDevicesQueryOpResult): void {
		const { hasNextPage, endCursor } = devices.discoveredDevicesConnection.pageInfo;

		const addedDevices: DiscoveryDeviceFragment[] = [];
		devices.discoveredDevicesConnection.edges.forEach((edge) => {
			if (edge.node) {
				this.discoveredDevicesMap.set(edge.node.id, edge.node);
				addedDevices.push(edge.node);
			}
		});

		// if there's more data on the server, trigger the next query.
		if (hasNextPage && endCursor && endCursor.length !== 0) {
			this.queryDiscoveredDevicesNetwork(endCursor);
			this.handleDevicesAdded(addedDevices);
			return;
		}

		this.handleDevicesAdded(addedDevices);
		this.discoveryComplete$.next(true);
	}
}
