import { WatchQueryFetchPolicy } from '@apollo/client';
import { BehaviorSubject, Observable } from 'rxjs';

import { InventoryDevice } from '@shure/cloud/shared/models/devices';
import { UpdateResponse } from '@shure/cloud/shared/models/http';

export type DeviceInventoryLoadingProgress = {
	state: 'WaitingToStart' | 'DiscoveringDevices' | 'QueryingInventoryData' | 'Completed';
	startTime: number;
	discoveredDeviceCount: number;
	inventoryDeviceCount: number;
	elapsedTime: number;
	percentComplete: number;
	popcornTimerExpired?: boolean;
};

export type DeviceInventoryEvent = {
	type: 'added' | 'updated' | 'removed';
	device: InventoryDevice;
};

export abstract class InventoryDevicesApiService {
	/**
	 * convenience reference to the device inventory if updates aren't needed.
	 */
	public abstract deviceInventory: BehaviorSubject<InventoryDevice[]>;

	/**
	 * Get # of inventory devices.
	 */
	public abstract getInventoryDevicesCount$(): Observable<number>;

	/**
	 * Get all inventory devices.
	 */
	public abstract getInventoryDevices$(): Observable<InventoryDevice[]>;

	/**
	 * Get observable to Inventory device events
	 */
	public abstract getInventoryDeviceEvents$(): Observable<DeviceInventoryEvent>;

	/**
	 * Get inventory device by ID.
	 */
	public abstract getInventoryDevice$(
		deviceId: string,
		fetchPolicy: WatchQueryFetchPolicy
	): Observable<InventoryDevice>;

	/**
	 * Set identify for a device.
	 */
	public abstract setIdentify(deviceId: string, identify: boolean): Observable<UpdateResponse<void, string>>;

	public abstract deviceInventoryLoadingProgress$(): Observable<DeviceInventoryLoadingProgress>;
}
